import axios from 'axios'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { HashRouter, matchPath, Redirect, Route, Switch, useLocation } from 'react-router-dom'
import Cookies, { CookieSetOptions } from 'universal-cookie'
//import { getContext } from '@microsoft/teams-js'
import { app } from '@microsoft/teams-js'
import { captureException, setUser } from '@sentry/browser'
import { ProgressIndicator } from 'office-ui-fabric-react/lib/ProgressIndicator'
import {
  feedBacks,
  goals,
  meetings,
  notes,
  pulse,
  recognitions,
  reviews,
  surveys,
  tasks
} from './constants/moduleNames'

import { Icon } from 'office-ui-fabric-react'
import DemoBanner from './components/custom/Banner/DemoBanner'
import ImpersonateBanner from './components/custom/Banner/ImpersonateBanner'

import DemoReturnModal from './components/DemoReturnModal'
import DemoUserModal from './components/DemoUserModal'

import * as jwt from 'jsonwebtoken'
import { initBeacon, initBeaconShowListener } from './helpers/beaconHelper'
import { setDefaultAxios, setDemoDefaultAxios } from './helpers/axiosHelper'
const lazy = (React as any).lazy

import classNames from 'classnames'
import { EncryptStorage } from 'encrypt-storage'
import queryString from 'query-string'
import UseInTeamsPopup from './components/UseInTeamsPopup'
import { getUrlParameter } from './helpers/meetingHelper'

import { allRoutes, errorEventRoutes, pageViewRoutes } from './constants/routes'
import {
  mixpanelButtonClickedEvent,
  mixpanelCustomEvent,
  mixpanelErrorEvent,
  mixpanelIdentifyUser,
  mixpanelPageViewEvent,
  mixpanelSwitchToLiveEnvironment
} from './helpers/mixPanelHelper'
import { useMeeting } from './context/MeetingContextProvider'
import { reaction } from 'mobx'
import { updateLocale } from 'moment'

const PulsePage = lazy(() => import('./pages/PulsePage'))
const GoalPage = lazy(() => import('./components/goals/GoalPage'))
const BackDrop = lazy(() => import('./components/BackDrop'))
const ModalGlobal = lazy(() => import('./components/ModalGlobal'))
const MobileNav = lazy(() => import('./components/MobileNav'))
const NavBar = lazy(() => import('./components/NavBar'))
const CombinedMeetingsPage = lazy(() => import('./pages/CombinedMeetingsPage'))
const OneOnOnePage = lazy(() => import('./pages/OneOnOnePage'))
const RedirectToRealOneOnOne = lazy(() => import('./components/RedirectToRealOneOnOne'))
const Progress = lazy(() => import('./components/Progress'))
const Login = lazy(() => import('./pages/Login'))
const MyPeople = lazy(() => import('./pages/MyPeople'))
const MyTeam = lazy(() => import('./pages/MyTeam'))
const RequestsPage = lazy(() => import('./pages/RequestsPage'))
const RequestPage = lazy(() => import('./pages/RequestPage'))
const UserPage = lazy(() => import('./pages/UserPage'))
const RecognitionsPage = lazy(() => import('./pages/RecognitionsPage'))
const FeatureRequestPage = lazy(() => import('./pages/FeatureRequestPage'))
const FeatureRequestTabPage = lazy(() => import('./pages/FeatureRequestTabPage'))
const SendOneOnOnePage = lazy(() => import('./pages/SendOneOnOnePage'))
const SendFeedbackPage = lazy(() => import('./pages/SendFeedbackPage'))
const SendTaskPage = lazy(() => import('./pages/SendTaskPage'))
const SendTaskMessageExtensionPage = lazy(() => import('./pages/SendTaskMessageExtensionPage'))
const SendRecognitionPage = lazy(() => import('./pages/SendRecognitionPage'))
const SendRecognitionComposePage = lazy(() => import('./pages/SendRecognitionComposePage'))
const SendRecognitionMessageExtensionPage = lazy(() => import('./pages/SendRecognitionMessageExtensionPage'))
const SendGoalMessageExtensionPage = lazy(() => import('./pages/SendGoalMessageExtensionPage'))
const SendFeedbackMessageExtensionPage = lazy(() => import('./pages/SendFeedbackMessageExtensionPage'))
const FeedbacksPage = lazy(() => import('./pages/FeedbacksPage'))
const FeedbackPage = lazy(() => import('./pages/FeedbackPage'))
const ReviewsPage = lazy(() => import('./pages/ReviewsPage'))
const ReviewPage = lazy(() => import('./pages/ReviewPage'))
const SurveyReportPage = lazy(() => import('./pages/SurveyReportPage'))
const Feedback360DegreePage = lazy(() => import('./pages/Feedback360DegreePage'))
const ReviewToSharePage = lazy(() => import('./pages/ReviewToSharePage'))
const GoalsPage = lazy(() => import('./pages/GoalsPage'))
const MeetingsPage = lazy(() => import('./pages/MeetingsPage'))
const GoalGroupPage = lazy(() => import('./pages/GoalGroupPage'))
const GoalsScreenSharePage = lazy(() => import('./pages/GoalsScreenSharePage'))
const NotFound = lazy(() => import('./pages/NotFound'))
const SearchPage = lazy(() => import('./pages/SearchPage'))
const NotesPage = lazy(() => import('./pages/NotesPage'))
const UserSettingsPage = lazy(() => import('./pages/UserSettingsPage'))
const MyProfile = lazy(() => import('./pages/MyProfile'))
const TeamsTabConfigurePage = lazy(() => import('./pages/TeamsTabConfigure'))
const SubscriptionThankYou = lazy(() => import('./pages/SubscriptionThankYou'))
const OnboardPage = lazy(() => import('./pages/OnboardPage'))
const PricingPage = lazy(() => import('./pages/PricingPage'))
const HomePage = lazy(() => import('./pages/HomePage'))
const ReviewPrintPage = lazy(() => import('./pages/ReviewPrintPage'))
const DemoOnboardPage = lazy(() => import('./pages/DemoOnboardPage'))

const cookies = new Cookies()

const clearCookie = async () => {
  try {
    const cookies = new Cookies()
    const cookieOptions: CookieSetOptions = {}

    if (process.env.NODE_ENV === 'production') {
      cookieOptions.domain = '.teamflect.com'
    }
    cookies.remove('x-region', cookieOptions)
    await axios.get('/api/authorize/clear-cookie', {
      withCredentials: true
    })
  } catch (e) {
    console.log(e)
  }
}
function getLastVisitedUrl() {
  const dataString = localStorage.getItem('lastVisitedUrl')

  if (dataString) {
    try {
      const data = JSON.parse(dataString)
      const currentTime = new Date().getTime()

      if (currentTime < data.expiration) {
        // The data is still valid
        return data.value
      } else {
        localStorage.removeItem('lastVisitedUrl')
      }
    } catch (error) {
      localStorage.removeItem('lastVisitedUrl')
    }
  }

  // Return null if no valid data is found
  return null
}

axios.defaults.headers.get['Pragma'] = 'no-cache'
axios.defaults.baseURL = process.env.API_URI

axios.interceptors.response.use(
  function (response) {
    return response
  },
  async function (error) {
    if (error?.response?.data?.error === 'INVALID_TOKEN') {
      localStorage.clear()
      await clearCookie()
      window.location.href = '/getStarted' + window.location.search
      return Promise.reject(error)
    }

    return Promise.reject(error)
  }
)

export const cachePrefix = 'xctm-0xd'
export const storageKey = 'xctm-0xd-enc'
const defaultBgImage = 'https://teamblee.blob.core.windows.net/teamflect/home-background.webp'

@inject('userStore')
@inject('windowStore')
@inject('modalStore')
@inject('dateFilterStore')
@inject('groupingStore')
@observer
export default class AppInner extends React.Component<any, any> {
  imgWidth = null
  imgHeight = null

  constructor(props, context) {
    super(props, context)
    this.state = {
      authorize: false,
      loginStatus: 0,
      platform: 0,
      errorMessage: '',
      error: '',
      hasError: false,
      refreshCounter: 5,
      textt: '',
      openingPage: 'Home',
      isDark: false,
      redirectFromAdaptive: null,
      loadingForImpersonate: false,
      loadingForDemo: false,
      inTeams: false
    }
  }

  async componentDidMount() {
    let parsed = queryString.parse(location.search)
    let isInTeams = parsed.inTeams && parsed.inTeams === 'true'
    if (isInTeams) {
      this.setState({ inTeams: true })
      /*app.initialize().then(() => {
        teamsCore.registerOnLoadHandler(data => {

          console.log('got load from TEAMS', data.contentUrl, data.entityId)
          console.log('got load from TEAMS', data) 
          app.getContext().then(context => {
            console.log('context',context)
          })
          // use contentUrl to route to correct page
          // invoke notifySuccess when ready
          app.notifySuccess()
        })
        teamsCore.registerBeforeUnloadHandler(readyToUnload => {
          // dispose resources and then invoke readyToUnload
          readyToUnload()
          return true
        })
      })
*/
      const lastVisitedUrl = getLastVisitedUrl()

      app.getContext().then(async context => {
        if (
          !context?.page?.subPageId?.includes('signupFromWebToTeams') &&
          !this.props.userStore.redirectedFromAdaptive &&
          context.page.subPageId
        ) {
          localStorage.removeItem('lastVisitedUrl')
          this.props.userStore.setRedirectedFromAdaptive()
          this.setState({ redirectFromAdaptive: context.page.subPageId })
        } else {
          if (
            lastVisitedUrl &&
            lastVisitedUrl !== window.location.hash + window.location.search &&
            !this.props.userStore.redirectedFromAdaptive &&
            !context?.chat?.id &&
            !context?.page?.subPageId &&
            !context?.team &&
            !context?.meeting
          ) {
            this.props.history.replace(lastVisitedUrl)
          }
        }
        let inExtension = getUrlParameter('inExtension', window.location.search)

        try {
          const { data } = await axios.post(
            process.env.GET_REGION_URI,
            {
              tenantId: context?.user.tenant.id,
              isOnlyReturnTenantRegion: true
            },
            {
              headers: {
                'Access-Control-Allow-Origin': '*',
                Accept: 'application/json',
                'Content-Type': 'application/json'
              }
            }
          )

          let region = data.region

          if (!region) {
            const res = await axios.head('/api/region')
            region = res.headers['x-region']
          }
          axios.defaults.headers.common['x-region'] = region
        } catch (error) {
          console.log('error', error)
        }

        this.checkTeamsUser(context?.user?.id, context?.user?.userPrincipalName)
        if (context?.app?.theme === 'dark' && inExtension) {
          this.setState({ isDark: true })
        } else {
          this.setState({ isDark: false })
        }
      })
    } else {
      let inOffice = parsed.inOffice && parsed.inOffice === 'true'
      if (inOffice) {
        //@ts-ignore
        Office.onReady(() => {
          this.authorizeSSO()
        })
      } else {
        let idToken = this.props.userStore.idToken

        if (!idToken) {
          try {
            idToken = await this.getIdToken()
          } catch (error) {
            console.log('error ' + error)
          }
        }
        if (idToken !== undefined && idToken !== 'error') {
          //this.setState({ loginStatus: 1 })

          this.checkWebUser(idToken)
        } else {
          this.setState({ loginStatus: 1 })
        }
      }
    }

    this.props.windowStore.setWidth(window.innerWidth)
    this.props.windowStore.setHeight(window.innerHeight)
    initBeaconShowListener()
  }

  getIdToken = async () => {
    if (process.env.ENVIRONMENT === 'slot') {
      this.props.userStore.setIdToken(cookies.get('idToken'))
      return cookies.get('idToken')
    }
    const res = await axios.get('/api/authorize/getIdToken', {
      withCredentials: true
    })

    this.props.userStore.setIdToken(res.data)

    return res.data
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props
    if (
      location !== prevProps.location &&
      !this.props.userStore.redirectedFromAdaptive &&
      !location.hash.includes('profile')
    ) {
      const expirationTime = new Date().getTime() + 2 * 60 * 60 * 1000 // 2 hours in milliseconds
      const data = {
        value: location.hash + location.search,
        expiration: expirationTime
      }
      localStorage.setItem('lastVisitedUrl', JSON.stringify(data))
    }
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV !== 'development') {
      // Catch errors in any components below and re-render with error message
      this.setState({
        hasError: true,
        error: error,
        errorInfo: errorInfo
      })
    }
    captureException(error)
    // You can also log error messages to an error reporting service here
  }

  updateDimensions = () => {
    let heightOfBg = 600
    if (this.imgHeight && this.imgWidth) {
      setTimeout(() => {
        let resizeElement = document.getElementById('appInner')
        heightOfBg = this.imgHeight * (resizeElement.clientWidth / this.imgWidth)
        heightOfBg = Math.round(heightOfBg)
        resizeElement.style.backgroundSize = `100% ${heightOfBg}px`
      }, 0)
    }
    this.props.windowStore.setWidth(window.innerWidth)
    this.props.windowStore.setHeight(window.innerHeight)
  }

  setInitialStates = (data, isCacheData, from, idToken) => {
    try {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.accessToken
      this.props.userStore.setUser(data.userData, from)
      if (data.userData.determinedDates) {
        this.props.dateFilterStore.setInitialDateFilter(data.userData.determinedDates, () => {})
        this.props.groupingStore.setInitialGroupBy(data.userData.determinedDates, () => {})
      }

      // Set the background image simultaneously filling the userStore
      if (this.props.userStore.tenantSettings && this.props.userStore.tenantSettings.backgroundImage) {
        this.getImgSize(this.props.userStore.tenantSettings.backgroundImage)
      }
      if (this.props.userStore.userSettings && this.props.userStore.userSettings.backgroundImage) {
        this.getImgSize(this.props.userStore.userSettings.backgroundImage)
      }

      initBeacon(data.userData)
      setUser({ email: data.userData.UPN })
      mixpanelIdentifyUser(data.userData, this.state.inTeams)
      this.setMomentLocale(data.userData)

      if (this.state.openingPage !== data.userData.tenant.settings.openingPage || this.state.loginStatus !== 2) {
        this.setState({
          openingPage: data.userData.tenant.settings.openingPage,
          loginStatus: 2
        })
      }

      if (!isCacheData) {
        const encryptStorage = new EncryptStorage(idToken, {
          prefix: cachePrefix
        })
        encryptStorage.setItem(storageKey, data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  setMomentLocale = userData => {
    if (userData.userSettings.firstDayOfWeek != undefined) {
      updateLocale('en', {
        week: {
          dow: userData.userSettings.firstDayOfWeek
        }
      })
    }
  }

  cacheCheckHelper = (key, from, cb) => {
    try {
      const encryptStorage = new EncryptStorage(key, {
        prefix: cachePrefix
      })
      const localStorageData = encryptStorage.getItem(storageKey)
      const decoded = jwt.decode(localStorageData.accessToken) as { [key: string]: any }

      const isLocalStorageDataValid = localStorageData && new Date().getTime() < (decoded?.exp || 0) * 1000
      if (isLocalStorageDataValid) {
        this.setInitialStates(localStorageData, true, from, key)
      }
      setTimeout(cb, isLocalStorageDataValid ? 3000 : 0)
    } catch (error) {
      console.log(error)
      cb()
    }
  }

  componentWillUnmount() {
    // window.removeEventListener('resize', this.updateDimensions)
  }

  getIdentityToken = ssoToken => {
    Office.context.mailbox.getUserIdentityTokenAsync(asyncResult => {
      this.cb(asyncResult, ssoToken)
    })
  }

  cb = (asyncResult, ssoToken) => {
    let token = asyncResult.value
    const decoded = jwt.decode(token) as { [key: string]: any }
    let appctxsender = decoded.appctxsender
    let appctxsenderArr = appctxsender.split('@')
    let tenantId = appctxsenderArr[1]
    axios
      .post(
        process.env.GET_REGION_URI,
        {
          tenantId: tenantId,
          isOnlyReturnTenantRegion: true
        },
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        }
      )
      .then(async response => {
        let region = response.data.region
        if (!region) {
          const res = await axios.head('/api/region')
          region = res.headers['x-region']
        }

        const maxAge2WeeksMs = 1000 * 60 * 60 * 24 * 14
        const cookieConfig: CookieSetOptions = {
          expires: new Date(Date.now() + maxAge2WeeksMs)
        }
        cookies.set('x-region', region, cookieConfig)
        axios.defaults.headers.common['x-region'] = region

        axios
          .post('/api/authorize/checkUser', { exchangeToken: token, ssoToken: ssoToken })
          .then(response => {
            if (response.data) {
              if (response.data.message === 'ok') {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.accessToken
                this.props.userStore.setUser(response.data.userData, 'outlook')
                if (response.data.userData.determinedDates) {
                  this.props.dateFilterStore.setInitialDateFilter(response.data.userData.determinedDates, () => {})
                  this.props.groupingStore.setInitialGroupBy(response.data.userData.determinedDates, () => {})
                }
                this.setState({ openingPage: response.data.userData.tenant.settings.openingPage })
                initBeacon(response.data.userData)

                setUser({ email: response.data.userData.UPN })
                setTimeout(() => {
                  this.setState({ loginStatus: 2 })
                }, 100)
              } else {
                this.setState({ errorMessage: response.data.message, admins: response.data.admins })
                setTimeout(() => {
                  this.setState({ loginStatus: 3 })
                }, 100)
              }
            }
          })
          .catch(error => {
            this.setState({ loginStatus: 5 })
          })
      })
      .catch(error => {
        // console.log('error ' + error)
        window.location.href = window.location.origin + '/getstarted?hasError=true'
      })
  }

  authorizeSSO = () => {
    this.getIdentityToken(null)
  }

  checkWebUser = async idToken => {
    let noErrorOnDemo = false
    let localStorageData = undefined

    try {
      const encryptStorage = new EncryptStorage(idToken, {
        prefix: cachePrefix
      })

      localStorageData = encryptStorage.getItem(storageKey)
    } catch (error) {
      console.log('error ' + error)
    }

    // get demoMode and selectedDemoUser from local storage
    let demoMode = localStorage.getItem('demoMode')
    let selectedDemoUser = localStorage.getItem('selectedDemoUser')

    if (demoMode === 'true' && selectedDemoUser) {
      noErrorOnDemo = await this.checkDemoUser(selectedDemoUser, this.props.userStore, localStorageData?.accessToken)
    }

    if (noErrorOnDemo) {
      return
    }

    this.cacheCheckHelper(idToken, 'web', () => {
      axios
        .post('/api/authorize/checkWebUser', { idToken: idToken })
        .then(response => {
          if (response.data) {
            if (response.data.message === 'ok') {
              this.setInitialStates(response.data, false, 'web', idToken)
            } else {
              this.setState({ errorMessage: response.data.message, admins: response.data.admins })
              setTimeout(() => {
                this.setState({ loginStatus: 3 })
              }, 500)
            }
          }
        })
        .catch(error => {
          console.log('error ' + error)
          this.setState({ loginStatus: 1 })
        })
    })
  }

  checkTeamsUser = async (id, upn) => {
    let demoMode = localStorage.getItem('demoMode')
    let selectedDemoUser = localStorage.getItem('selectedDemoUser')
    let noErrorOnDemo = false

    let localStorageData = undefined

    try {
      const encryptStorage = new EncryptStorage(upn, {
        prefix: cachePrefix
      })

      localStorageData = encryptStorage.getItem(storageKey)
    } catch (error) {
      console.log('error ' + error)
    }

    if (demoMode === 'true' && selectedDemoUser) {
      noErrorOnDemo = await this.checkDemoUser(selectedDemoUser, this.props.userStore, localStorageData?.accessToken)
    }

    if (noErrorOnDemo) {
      return
    }

    this.cacheCheckHelper(upn, 'teams', () => {
      axios
        .post('/api/authorize/checkTeamsUser', { id: id, upn: upn })
        .then(response => {
          if (response.data) {
            if (response.data.message === 'ok') {
              this.setInitialStates(response.data, false, 'teams', upn)
            } else if (response.data.message === 'userNotFoundError') {
              this.setState({ errorMessage: response.data.message })
              setTimeout(() => {
                this.setState({ loginStatus: 4 })
              }, 500)
            } else {
              this.setState({ errorMessage: response.data.message })
              setTimeout(() => {
                this.setState({ loginStatus: 3 })
              }, 500)
            }
          } else {
            this.setState({ loginStatus: 4 })
          }
        })
        .catch(error => {
          this.setState({ loginStatus: 4 })
        })
    })
  }

  backUserFromImpersonate = () => {
    this.setState({
      loadingForImpersonate: true
    })
    const platform = this.props.userStore.platform
    axios
      .post('/api/common/checkBackToUser', {
        userId: this.props.userStore.impersonateOldUser,
        adminLogin: false
      })
      .then(response => {
        if (response.data) {
          if (response.data.message === 'ok') {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.accessToken
            axios.defaults.headers.common['x-region'] = cookies.get('x-region') || localStorage.getItem('x-region')
            this.props.userStore.setUser({ ...response.data.userData }, platform)
            this.setState({
              loadingForImpersonate: false
            })
          } else {
          }
        }
      })
      .catch(error => {
        console.log('error ' + error)
      })
  }

  backUserFromDemo = () => {
    setDefaultAxios()
    this.setState({
      loadingForDemo: true
    })
    const platform = this.props.userStore.platform
    mixpanelSwitchToLiveEnvironment()
    axios
      .post('/api/common/checkBackToUserForDemo', {
        // userId: this.props.userStore.impersonateOldUser,
        adminLogin: false
      })
      .then(response => {
        if (response.data) {
          if (response.data.message === 'ok') {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.accessToken
            this.props.userStore.setUser({ ...response.data.userData }, platform)
            this.props.userStore.setUserProfilePic(response.data.userData.UPN)

            this.setState({
              loadingForDemo: false
            })

            if (this.props.history.location.pathname === '/home') {
              this.props.history.push('/user/' + response.data.userData.UPN)
            } else {
              this.props.history.push('/')
            }
          } else {
          }
        }
      })
      .catch(error => {
        console.log('error ' + error)
        // set local storage demo mode to false
      })
  }

  getOpeningPageUrl = openingPage => {
    if (openingPage) {
      switch (openingPage) {
        case 'Meeting':
          return '/me/meetings'
        case 'Recognition':
          return '/me/recognitions/company'
        case 'Feedback':
          return '/me/feedback/received'
        case 'Task':
          return '/me/tasks/to'
        case 'Note':
          return '/me/notes'
        case 'Goal':
          return '/me/goals'
        case 'Review':
          return '/me/reviews'

        default:
          return 'home'
        // code block
      }
    } else {
      return 'home'
    }
  }

  startCounter = async () => {
    if (this.state.refreshCounter < 1) {
      window.location.reload()
    } else {
      setTimeout(() => this.setState({ refreshCounter: this.state.refreshCounter - 1 }), 1000)
    }
  }

  getImgSize = async url => {
    let img = new Image()
    img.src = url
    img.onload = () => {
      this.imgHeight = img.height
      this.imgWidth = img.width

      this.updateDimensions()
    }
  }

  getBgImage = () => {
    let bgImage = 'https://teamblee.blob.core.windows.net/teamflect/home-background.webp'

    const demoMode = localStorage.getItem('demoMode')
    const selectedDemoUser = localStorage.getItem('selectedDemoUser')

    if (this.props.userStore.tenantSettings && this.props.userStore.tenantSettings.backgroundImage) {
      bgImage = this.props.userStore.tenantSettings.backgroundImage
    }
    if (this.props.userStore.userSettings && this.props.userStore.userSettings.backgroundImage) {
      bgImage = this.props.userStore.userSettings.backgroundImage
    }
    let style: React.CSSProperties = {
      paddingBottom: this.props.userStore.impersonateMode || (demoMode === 'true' && selectedDemoUser) ? '16px' : '0px'
    }

    if (bgImage === defaultBgImage) {
      style = {
        backgroundImage: `linear-gradient(transparent -5%, rgb(243, 242, 241)), url(${bgImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 600px',
        backgroundPosition: 'absolute',
        paddingBottom:
          this.props.userStore.impersonateMode || (demoMode === 'true' && selectedDemoUser) ? '80px' : '0px'
      }
    } else {
      if (location.hash.includes('/home')) {
        let heightOfBg = 600
        let navWidth = this.props.windowStore.width > 1400 ? 186 : 65
        if (this.imgHeight && this.imgWidth) {
          heightOfBg = this.imgHeight * ((this.props.windowStore.width - navWidth) / this.imgWidth)
          heightOfBg = Math.round(heightOfBg)
        }
        style = {
          backgroundImage: `linear-gradient(transparent -25%, rgb(243, 242, 241)), url(${bgImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: `100% ${heightOfBg}px`,
          backgroundPosition: 'absolute',
          paddingBottom:
            this.props.userStore.impersonateMode || (demoMode === 'true' && selectedDemoUser) ? '80px' : '0px'
        }
      }
    }

    return style
  }

  async onUserSelectedHelper(displayName: string, userStore: any, setState: Function) {
    try {
      setState({
        loadingForDemo: true
      })

      setDemoDefaultAxios(displayName)

      if (userStore && userStore.oid) {
        const { data } = await axios.post('/api/web/getDemoUser', {
          oid: userStore.oid,
          displayName: displayName
        })

        axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.bearer

        const realUserId = !userStore.demoMode ? userStore.oid : userStore.impersonateOldUser
        const platform = userStore.platform
        const demoUser = data.userData
        demoUser.demoMode = true
        demoUser.impersonateOldUser = realUserId
        demoUser.selectedDemoUser = displayName

        userStore.setUser(demoUser, platform)
        userStore.setUserProfilePic(data.userData.UPN)

        if (!data.userData.graphAccessToken) {
          const { data } = await axios.get('/api/common/getNewTokens')
          userStore.setNewToken(data.graphAccessToken, data.graphTokenExpires)
        }

        setState({
          loadingForDemo: false
        })
        if (this.props.history.location.pathname === '/home') {
          this.props.history.push('/user/' + data.userData.UPN)
        } else {
          this.props.history.push('/')
        }
      }
    } catch (error) {
      console.log('error', error)

      setDefaultAxios()

      localStorage.setItem('demoMode', 'false')
      localStorage.removeItem('selectedDemoUser')

      setState({
        loadingForDemo: false
      })
    }
  }

  async checkDemoUser(displayName: string, userStore: any, accessToken: any) {
    try {
      setDemoDefaultAxios(displayName)

      if (accessToken) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken
      }

      if (userStore) {
        const { data } = await axios.post('/api/web/getDemoUser', {
          oid: userStore.oid,
          displayName: displayName
        })

        axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.bearer

        const realUserId = !userStore.demoMode ? userStore.oid : null
        const platform = userStore.platform
        const demoUser = data.userData
        demoUser.demoMode = true
        demoUser.impersonateOldUser = realUserId

        if (!data.userData.graphAccessToken) {
          const { data } = await axios.get('/api/common/getNewTokens')
          userStore.setNewToken(data.graphAccessToken, data.graphTokenExpires)
        }

        // login status 2 means user is logged in
        userStore.setUser(demoUser, platform)
        userStore.setUserProfilePic(data.userData.UPN)

        this.setState({
          loadingForDemo: false,
          loginStatus: 2
        })

        return true
      } else {
        return false
      }
    } catch (error) {
      console.log('error', error)

      setDefaultAxios()

      // set local storage demo mode to false
      localStorage.setItem('demoMode', 'false')
      localStorage.removeItem('selectedDemoUser')

      this.setState({
        loadingForDemo: false
      })

      return false
    }
  }

  render() {
    const { redirectFromAdaptive, loginStatus, openingPage } = this.state
    const { userStore, modalStore, windowStore } = this.props
    const history = this.props.history

    const AppInner = () => {
      let location = useLocation()

      const demoMode = localStorage.getItem('demoMode')
      const selectedDemoUser = localStorage.getItem('selectedDemoUser')

      const allowedParams = ['mode', 'feature']
      const appInnerContainer = React.useRef(null)

      // Fetch meetings initially
      let { fetchMeetings, getMeetings, resetMeetings, getUpcomingMeetings } = useMeeting()

      React.useEffect(() => {
        const { meetings } = getMeetings({})
        if (meetings.length === 0) {
          getUpcomingMeetings()
          fetchMeetings({})
        }
        reaction(
          () => this.props.userStore.oid,
          (value, reaction) => {
            if (value) {
              resetMeetings()
            }
          }
        )
      }, [])

      React.useEffect(() => {
        let route = pageViewRoutes.find(r => matchPath(location.hash, { path: r.path, exact: true }))
        let paramKeyValuePairs = []
        if (route) {
          if (route.hasParams) {
            let paramKeys = route.path.split('/').reduce((acc, param, idx) => {
              if (param.includes(':')) {
                acc.push({ key: param.replace(':', '').replace('?', ''), index: idx })
              }
              return acc
            }, [])
            let paramValues = location.hash.split('/')
            paramKeyValuePairs = paramKeys.reduce((acc, param) => {
              if (allowedParams.includes(param.key)) acc[param.key] = paramValues[param.index]
              return acc
            }, {})
          }
          mixpanelPageViewEvent(route.name, paramKeyValuePairs)
        }
      }, [location])

      // track width of appInnerContainer
      React.useEffect(() => {
        if (appInnerContainer.current) {
          let resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
              this.updateDimensions()
            }
          })
          resizeObserver.observe(appInnerContainer.current)
          return () => {
            resizeObserver.disconnect()
          }
        }
      }, [appInnerContainer.current])

      return (
        <HashRouter>
          <>
            <Route path='/onboard/:redirectTo?' component={OnboardPage} />
            <Route path='/pricing' component={PricingPage} />
            <Route path='/demo' component={DemoOnboardPage} />
            <Route path='/feature-suggest' component={FeatureRequestTabPage} />
            <div
              style={['onboard', 'pricing'].some(t => window.location.hash.includes(t)) ? { display: 'none' } : {}}
              id='containerin'
              className={classNames({ dark: this.state.isDark })}
            >
              {this.props.userStore.impersonateMode && (
                <ImpersonateBanner
                  backgroundColor={'#fef7b2'}
                  stopButtonColor={'#080808'}
                  loading={this.state.loadingForImpersonate}
                  content={
                    <div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginRight: '10px', marginTop: 2 }}>
                          <Icon
                            iconName='Warning'
                            style={{
                              fontWeight: 'bold'
                            }}
                          />
                        </div>
                        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
                          You are impersonating {this.props.userStore.displayName}
                        </div>
                      </div>
                    </div>
                  }
                  onClose={() => {
                    this.backUserFromImpersonate()
                  }}
                />
              )}

              {demoMode && selectedDemoUser && (
                <DemoBanner
                  backgroundColor={'#fef7b2'}
                  stopButtonColor={'#080808'}
                  loading={this.state.loadingForDemo}
                  content={
                    <div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginRight: '10px', marginTop: 2 }}>
                          <Icon
                            iconName='BoxPlaySolid'
                            style={{
                              fontWeight: 'bold'
                            }}
                          />
                        </div>
                        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
                          You are now in Teamflect's demo environment. You can always switch between the demo and your
                          real account.
                        </div>
                      </div>
                    </div>
                  }
                  onClose={() => {
                    mixpanelButtonClickedEvent('Switch to real account', 'DemoBanner')
                    this.props.modalStore.showModal(
                      <DemoReturnModal
                        userStore={userStore}
                        modalStore={modalStore}
                        onClick={() => {
                          this.backUserFromDemo()
                        }}
                      />,
                      true,
                      660
                    )
                  }}
                  refreshClick={() => {
                    if (demoMode === 'true' && selectedDemoUser) {
                      this.onUserSelectedHelper(this.props.userStore.displayName, userStore, this.setState.bind(this))
                    }
                  }}
                  demoClick={async () => {
                    this.props.modalStore.showModal(
                      <DemoUserModal
                        userStore={userStore}
                        modalStore={modalStore}
                        onUserSelected={displayName => {
                          this.onUserSelectedHelper(displayName, userStore, this.setState.bind(this))
                        }}
                      />,
                      true,
                      660
                    )
                  }}
                  demoText={this.props.userStore.displayName}
                  demoLoading={this.state.loadingForDemo}
                />
              )}

              <ModalGlobal />
              <Route path='/' render={props => <MobileNav routeProps={props} />} />

              <BackDrop />
              <div id='nav-and-appInner'>
                <Route path='/' render={props => <NavBar routeProps={props} />} />
                <UseInTeamsPopup userStore={userStore} windowStore={windowStore} />
                <div
                  id={'appInner'}
                  className={classNames({
                    dark: this.state.isDark
                  })}
                  style={this.getBgImage()}
                  ref={appInnerContainer}
                >
                  <div className='appInner-container'>
                    <React.Suspense
                      fallback={
                        <div>
                          <ProgressIndicator />
                        </div>
                      }
                    >
                      <Switch>
                        <Route
                          exact
                          path='/'
                          render={props => {
                            return loginStatus === 1 ? (
                              <Redirect
                                to={{
                                  pathname: '/getstarted',
                                  state: { from: props.location }
                                }}
                              />
                            ) : redirectFromAdaptive ? (
                              <Redirect to={redirectFromAdaptive} />
                            ) : openingPage != null ? (
                              <Redirect
                                to={{
                                  pathname: `${this.getOpeningPageUrl(openingPage)}/`
                                }}
                              />
                            ) : (
                              <Redirect
                                to={{
                                  pathname: '/home'
                                }}
                              />
                            )
                          }}
                        />
                        <Route exact path='/home' component={HomePage} />
                        <Route exact path='/home/my-people' component={HomePage} />
                        <Route exact path='/me' component={MyPeople} />
                        <Route path={`/me/${recognitions}/:mode?`} component={RecognitionsPage} />
                        <Route path={`/me/${feedBacks}/:mode?`} component={FeedbacksPage} />
                        <Route path={'/me/requests'} component={RequestsPage} />
                        <Route path={`/me/${tasks}/:mode?`} component={RequestsPage} />
                        <Route path={`/me/${meetings}/:mode?`} component={MeetingsPage} />
                        <Route path={`/me/${notes}`} component={NotesPage} />
                        <Route path={`/me/${pulse}`} component={PulsePage} />
                        <Route path={`/me/${goals}/:mode?/:id?`} component={GoalsPage} />
                        <Route path={`/me/${reviews}/:mode?`} component={ReviewsPage} />
                        <Route path={`/me/${surveys}/:mode?`} component={ReviewsPage} />
                        <Route path={`/user/:upn/${tasks}/:mode?`} component={RequestsPage} />
                        <Route path={`/user/:upn/${feedBacks}/:mode?`} component={FeedbacksPage} />
                        <Route path={`/user/:upn/${recognitions}/:mode?`} component={RecognitionsPage} />
                        <Route path={`/user/:upn/${notes}`} component={NotesPage} />
                        <Route path={`/user/:upn/${goals}/:mode?/:id?`} component={GoalsPage} />
                        <Route path='/grouppage/:id?' component={GoalGroupPage} />
                        <Route path='/goalshare/:id?/:emails?/:managerUPN?' component={GoalsScreenSharePage} />
                        <Route path={`/profile/:oid/:oid2`} component={UserPage} />
                        <Route path={`/user/:upn/${reviews}/:mode?`} component={ReviewsPage} />
                        <Route path={`/user/:upn/${surveys}/:mode?`} component={ReviewsPage} />
                        <Route path='/teamflectmeeting/:id' component={RedirectToRealOneOnOne} />
                        <Route path='/user/:upn/:feature?' component={UserPage} />
                        <Route path='/newtaskmessageextension' component={SendTaskMessageExtensionPage} />
                        <Route path='/newtask' component={SendTaskPage} />
                        <Route path='/newoneonone' component={SendOneOnOnePage} />
                        <Route path='/newfeedback' component={SendFeedbackPage} />
                        <Route path='/newfeedbackmessageextension' component={SendFeedbackMessageExtensionPage} />
                        <Route path='/newrecognition' component={SendRecognitionPage} />
                        <Route path='/newrecognitioncompose' component={SendRecognitionComposePage} />
                        <Route path='/newrecognitionmessageextension' component={SendRecognitionMessageExtensionPage} />
                        <Route path='/newgoalmessageextension' component={SendGoalMessageExtensionPage} />
                        <Route path='/feedback/:id' component={FeedbackPage} />
                        <Route path='/task/:id' component={RequestPage} />
                        <Route path='/request/:id' component={RequestPage} />
                        <Route path='/meeting/:id/:startDate?/:azureId?/:deleted?' component={CombinedMeetingsPage} />
                        <Route path='/oneonone/:id' component={OneOnOnePage} />
                        <Route path='/goal/:id' component={GoalPage} />
                        <Route path='/review/:id' component={ReviewPage} />
                        <Route path='/survey/:id' component={ReviewPage} />
                        <Route path='/surveyreport/:query' component={SurveyReportPage} />
                        <Route path='/360degree/:id' component={Feedback360DegreePage} />
                        <Route path='/reviewtoshare/:id' component={ReviewToSharePage} />
                        <Route path='/getstarted' component={Login} />
                        <Route path='/search' component={SearchPage} />
                        <Route path='/feature-requests' component={FeatureRequestPage} />
                        <Route path='/feature-requests-extension' component={FeatureRequestPage} />
                        <Route path='/subscription-thank-you' component={SubscriptionThankYou} />
                        {/*@ts-ignore*/}
                        <Route {...this.props} path='/team' component={MyTeam} />
                        <Route path='/my-profile' component={MyProfile} />
                        <Route path='/settings' component={UserSettingsPage} />
                        <Route path='/teamstabconfigure' component={TeamsTabConfigurePage} />
                        <Route path='/reviewprint' component={ReviewPrintPage} />
                        <Route component={NotFound} />
                      </Switch>
                    </React.Suspense>
                  </div>
                </div>
              </div>
            </div>
          </>
        </HashRouter>
      )
    }

    let parsed = queryString.parse(location.search)
    if (parsed.token && !window.location.hash.includes('subscription')) {
      return <Redirect to={'/#/subscription-thank-you?token=' + encodeURIComponent(parsed.token)} />
    }

    if (this.state.loginStatus === 0) {
      return (
        <Progress
          isDark={this.state.isDark}
          title=''
          logo={
            this.state.isDark
              ? 'https://teamblee.blob.core.windows.net/teamblee/teamflectwhitesmall.svg'
              : 'assets/logo-filled.png'
          }
          message='Logging you in...'
        />
      )
    } else if (this.state.loginStatus === 1) {
      const currentUrl = new URL(window.location.href)
      const queryString = currentUrl.search

      let redirectUri = ''
      if (encodeURIComponent(window.location.hash) === '') {
        redirectUri = queryString
      } else {
        redirectUri = '?redirectTo=' + encodeURIComponent(window.location.hash)
        if (queryString.length > 0) {
          redirectUri = '&' + queryString.substring(1)
        }
      }
      return <Redirect to={'/getstarted' + redirectUri} />
    } else if (this.state.loginStatus === 4) {
      let redirectUri =
        encodeURIComponent(window.location.hash) === '' ? '' : '&redirectTo=' + encodeURIComponent(window.location.hash)
      let parsed = queryString.parse(location.search)
      let isInExtension = parsed.inExtension && parsed.inExtension === 'true' ? '&inExtension=true' : ''
      if (this.state.errorMessage === 'userNotFoundError') {
        return (
          <Redirect
            to={'/getstarted?inTeams=true&hasError=true&errorMessage=userNotFoundError' + isInExtension + redirectUri}
          />
        )
      }
      return <Redirect to={'/getstarted?inTeams=true' + isInExtension + redirectUri} />
    } else if (this.state.loginStatus === 5) {
      let redirectUri =
        encodeURIComponent(window.location.hash) === '' ? '' : '&redirectTo=' + encodeURIComponent(window.location.hash)
      return <Redirect to={'/getstarted?inOffice=true' + redirectUri} />
    } else if (this.state.loginStatus === 3) {
      return <Redirect to={'/loginerror?message=' + this.state.errorMessage + '&admins=' + this.state.admins} />
    } else {
      if (
        !this.props.userStore.hasSeenOnboardingModal &&
        !this.props.windowStore.isMobile &&
        !window.location.hash.includes('onboard')
      ) {
        let redirectUri =
          encodeURIComponent(window.location.hash) === '' ? '' : encodeURIComponent(window.location.hash)
        return <Redirect to={'/#/onboard/' + redirectUri} />
      }
      if (this.state.hasError) {
        this.startCounter()
        return (
          <div
            style={{
              height: '100vh',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              backgroundColor: '#F4F4F4',
              flexDirection: 'column'
            }}
          >
            <img style={{ height: 500 }} src='assets/images/oops.jpg' />
            <span style={{ fontSize: 24 }}>Refreshing in {this.state.refreshCounter}... </span>
          </div>
        )
      }
      return <AppInner />
    }
  }
}
